const data = [
  [
    {
      src: "/partners/stelllantis.png",
      alt: "stelllantis",
      height: 39,
      width: 179,
    },
    {
      src: "/partners/ald-automotive.png",
      alt: "ald",
      height: 47,
      width: 164,
    },
    {
      src: "/partners/bmw-group.png",
      alt: "bmw-group",
      height: 44,
      width: 90,
    },
    {
      src: "/partners/lexus.png",
      alt: "lexus",
      height: 44,
      width: 178,
    },
    {
      src: "/partners/mercedes-benz.png",
      alt: "mercedes-benz",
      height: 47,
      width: 190,
    },
  ],
  [
    {
      src: "/partners/renault.png",
      alt: "renault",
      height: 77,
      width: 92,
    },
    {
      src: "/partners/dacia.png",
      alt: "dacia",
      height: 57,
      width: 110,
    },
    {
      src: "/partners/smart.png",
      alt: "smart",
      height: 100,
      width: 100,
    },
    {
      src: "/partners/toyota.png",
      alt: "toyota",
      height: 63,
      width: 114,
    },
  ],
  [
    {
      src: "/partners/Leonori-logo.png",
      alt: "Leonori",
      height: 28,
      width: 255,
    },
    {
      src: "/partners/fiori-rent.png",
      alt: "Fiori Rent",
      height: 74,
      width: 200,
    },
    {
      src: "/partners/Bisson-Logo.png",
      alt: "BissonAuto",
      height: 60,
      width: 296,
    },
    {
      src: "/partners/unipol-rental.png",
      alt: "unipol",
      height: 49,
      width: 222,
    },
    {
      src: "/partners/athlon.png",
      alt: "athlon",
      height: 41,
      width: 153,
    },
  ],
];

export default data;
