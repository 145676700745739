import React, { useEffect } from "react";
import { Icon4 } from "./Icons";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Header from "./Header";
import ipad from "../assets/images/png/ipad.png";
import videoWebmV from "../assets/Video-MattIA_Daicar-Group-vertical4.webm";
import arrow from "../assets/images/svg/arrow.svg";
import { TabRightIcon } from "./Icons";
import comeLottie from "../assets/lotties/tabs-1.json";
import marketingLottie from "../assets/lotties/tabs-2.json";
import techDrivenLottie from "../assets/lotties/tabs-3.json";
import realTimeLottie from "../assets/lotties/tabs-4.json";
import crmLottie from "../assets/lotties/tabs-5.json";
import LottieHero from "./LottieHero";
// import VisibilitySensor from "react-visibility-sensor";

const Hero = ({locale: {Hero}}) => {
  let tl;
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  useEffect(() => {
    let mm = gsap.matchMedia();
    mm.add("(min-width: 1600px)", () => {
      gsap.set(["#hero-sub-heading-two", "#hero-sub-heading-two h2"], {
        zIndex: "-1",
      });
      gsap.set("#hero-sub-heading-one h2", {
        y: "170px",
      });
      gsap.set(".arrow-hero-one", {
        y: "30px",
        scale: 1.1,
      });
      gsap.set(".arrow-hero-two", {
        yPercent: -9,
        xPercent: 100,
        scale: 0.4,
      });
      gsap.set(".arrow-hero-three", {
        xPercent: -90,
        yPercent: -26,
        scale: 0.2,
      });
      gsap.set("#hero-large-arrow", {
        left: "50%",
        xPercent: -50,
        top: "76%",
      });
      gsap.set("#intro-content", {
        display: "none",
      });
      gsap.from([".arrow-hero-one", ".arrow-hero-two", ".arrow-hero-three"], {
        y: 500,
        stagger: 0.1,
        duration: 1.5,
        delay: 3.0,
      });
      gsap.set(
        [
          "#hero-sub-heading-one h3",
          "#hero-sub-heading-one p",
          "#hero-sub-heading-two h2",
          "#hero-sub-heading-two h3",
          "#hero-sub-heading-two p",
          "#hero-para-one",
          "#hero-para-two",
        ],
        {
          autoAlpha: 0,
        }
      );
      gsap.set("#hero-outline-arrow", {
        autoAlpha: 0,
        yPercent: 100,
      });
      gsap.set(["#hero-ipad", "#hero-ipad-white"], {
        scale: 2,
        top: "45%",
        left: "50%",
        xPercent: -50,
        yPercent: -50,
      });
      gsap.set("#main-content", {
        y: "-130px",
      });
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#hero",
          start: "top top",
          end: "+=500%",
          pin: true,
          scrub: 1,
          fastScrollEnd: true,
        },
      });

      tl.to(".arrow-hero-one", {
        yPercent: -200,
        autoAlpha: 0,
      })
        .to(
          ".arrow-hero-three",
          {
            yPercent: -300,
            autoAlpha: 0,
          },
          "<"
        )
        .to("#hero-sub-heading-one h2", {
          y: "0px",
        })
        .to(
          ".arrow-hero-two",
          {
            xPercent: 73,
            yPercent: -8,
            scale: 0.19,
            rotate: "45deg",
          },
          "<"
        )
        .set("#hero-outline-arrow", {
          autoAlpha: 1,
        })
        .set(
          "#hero-ipad",
          {
            autoAlpha: 1,
          },
          "<"
        )
        .to(
          [
            "#hero-sub-heading-one",
            "#hero-sub-heading-one h3",
            "#hero-sub-heading-one p",
            "#hero-para-one",
          ],
          {
            autoAlpha: 1,
            zIndex: 10,
          }
        )
        .to(
          [
            "#hero-sub-heading-one h2"
          ],
          {
            scale: 0.7
          }
        )
        .to(
          [
            "#hero-sub-heading-two h2"
          ],
          {
            scale: 0.82
          }
        )
        .to("#hero-ipad", {
          scale: 1,
        })
        .to(
          "#main-content",
          {
            scale: 1,
          },
          "<"
        )
        .to("#hero-outline-arrow", {
          yPercent: -200,
          duration: 5,
        })
        .to(
          [
            "#hero-sub-heading-one h2",
            "#hero-sub-heading-one h3",
            "#hero-sub-heading-one p",
            "#hero-para-one",
          ],
          {
            autoAlpha: 0,
          },
          "<1"
        )
        .to(
          [
            "#hero-sub-heading-two",
            "#hero-sub-heading-two h2",
            "#hero-sub-heading-two h3",
            "#hero-sub-heading-two p",
            "#hero-para-two",
          ],
          {
            autoAlpha: 1,
            zIndex: 10,
          },
          "<"
        )
        .to(
          "#hero_section",
          {
            height: 0,
            duration: 2,
          },
          "<"
        )
        .set(
          "#hero-ipad-white",
          {
            scale: 0.8,
          },
          ">"
        )
        .to(
          ["#hero-ipad", ".hero_slide_2"],
          {
            scale: 0.8,
          },
          ">"
        )
        .to(
          ".hero_slide_1",
          {
            scale: 0.8,
          },
          "<"
        )
        .to(
          ".arrow-hero-two",
          {
            xPercent: 58,
            yPercent: -8,
            scale: 0.13,
            rotate: "45deg",
          },
          "<"
        )
        .to(
          "#hero-ipad",
          {
            autoAlpha: 0,
          },
          ">"
        )
        .to(
          "#hero-ipad-white",
          {
            opacity: 1,
          },
          "<"
        )
        .set(
          "#main-content",
          {
            display: "none",
          },
          "<"
        )

        //////////
        .addLabel("button_0")
        .to(
          ".active_button_0",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "<"
        )
        .set(
          "#intro-content",
          {
            display: "block",
          },
          "<20%"
        )
        .fromTo(
          ".left_text_1",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
          },
          "<"
        )
        .to("#hero-large-arrow", {
          top: "73%",
        })
        // .fromTo(
        //   ".card_img_0",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     top: "0px",
        //   }
        // )
        .to(
          ".side_icon",
          {
            bottom: "10%",
          },
          "-=.5"
        )
        // .to(".card_img_0", {
        //   opacity: 0,
        // })
        // .fromTo(
        //   ".card_img_1",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_0", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_1")
        .to(
          ".active_button_1",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "70%",
        })
        .fromTo(
          ".left_text_1",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          },
          "-=.5"
        )
        .fromTo(
          ".left_text_2",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
          }
        )
        .to(
          ".side_icon",
          {
            bottom: "30%",
          },
          "-=.5"
        )

        // .to(".card_img_1", {
        //   opacity: 0,
        // })
        // .fromTo(
        //   ".card_img_2",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_1", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_2")
        .to(
          ".active_button_2",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "64%",
        })
        .fromTo(
          ".left_text_2",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          },
          "-=.5"
        )
        .fromTo(
          ".left_text_3",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
          }
        )
        .to(
          ".side_icon",
          {
            bottom: "35%",
          },
          "-=.5"
        )

        // .to(".card_img_2", {
        //   opacity: 0,
        // })
        // .fromTo(
        //   ".card_img_3",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_2", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_3")
        .to(".active_button_3", {
          background: "rgba(83, 132, 153, 0.5)",
          rotate: "-3deg",
        })
        .to("#hero-large-arrow", {
          top: "58%",
        })
        .fromTo(
          ".left_text_3",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          },
          "-=.5"
        )
        .fromTo(
          ".left_text_4",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
          }
        )
        .to(
          ".side_icon",
          {
            bottom: "40%",
          },
          "-=.5"
        )
        // .to(".card_img_3", {
        //   opacity: 0,
        // })
        // .fromTo(
        //   ".card_img_4",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_3", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_4")
        .to(
          ".active_button_4",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "52%",
        })
        .fromTo(
          ".left_text_4",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          },
          "-=.5"
        )
        .fromTo(
          ".left_text_5",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
          }
        )
        .to(
          ".side_icon",
          {
            bottom: "45%",
          },
          "-=.5"
        )
        .to(["#para_text_01 button", "#para_text_01 p"], {
          color: "white",
        })
        .to("#root", {
          backgroundColor: "#29424D",
        });
    });

    mm.add("(min-width: 1000px) and (max-width:1298px)", () => {
      gsap.set("#main-content", {
        y: "-60px",
      });
      gsap.set(["#hero-sub-heading-two", "#hero-sub-heading-two h2"], {
        zIndex: "-1",
      });
      gsap.set("#hero-sub-heading-one h2", {
        y: "100px",
      });
      gsap.set(".arrow-hero-one", {
        scale: 0.8,
      });
      gsap.set(".arrow-hero-two", {
        yPercent: -11,
        xPercent: 75,
        scale: 0.3,
      });
      gsap.set(".arrow-hero-three", {
        xPercent: -65,
        yPercent: -22,
        scale: 0.15,
      });
      gsap.set("#hero-large-arrow", {
        left: "50%",
        xPercent: -50,
        top: "70%",
      });
      gsap.set("#intro-content", {
        display: "none",
      });
      gsap.from([".arrow-hero-one", ".arrow-hero-two", ".arrow-hero-three"], {
        y: 500,
        stagger: 0.1,
        duration: 1.5,
        delay: 3.0,
      });

      gsap.set(
        [
          "#hero-sub-heading-one h3",
          "#hero-sub-heading-one p",
          "#hero-sub-heading-two h2",
          "#hero-sub-heading-two h3",
          "#hero-sub-heading-two p",
          "#hero-para-one",
          "#hero-para-two",
        ],
        {
          autoAlpha: 0,
        }
      );

      gsap.set("#hero-outline-arrow", {
        autoAlpha: 0,
        yPercent: 100,
      });

      gsap.set(["#hero-ipad", "#hero-ipad-white"], {
        scale: 2,
        top: "45%",
        left: "50%",
        xPercent: -50,
        yPercent: -50,
      });
      gsap.set("#main-content", {
        y: "-70px",
      });
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#hero",
          start: "top top",
          end: "+=500%",
          pin: true,
          scrub: 1,
          fastScrollEnd: true,
        },
      });

      tl.to(".arrow-hero-one", {
        yPercent: -200,
        autoAlpha: 0,
      })
        .to(
          ".arrow-hero-three",
          {
            yPercent: -300,
            autoAlpha: 0,
          },
          "<"
        )
        .to("#hero-sub-heading-one h2", {
          y: "0px",
        })
        .to(
          ".arrow-hero-two",
          {
            xPercent: 78,
            yPercent: 3,
            scale: 0.1,
            rotate: "45deg",
          },
          "<"
        )
        .set("#hero-outline-arrow", {
          autoAlpha: 1,
        })
        .set(
          "#hero-ipad",
          {
            autoAlpha: 1,
          },
          "<"
        )
        .to(
          [
            "#hero-sub-heading-one",
            "#hero-sub-heading-one h3",
            "#hero-sub-heading-one p",
            "#hero-para-one",
          ],
          {
            autoAlpha: 1,
            zIndex: 10,
          }
        )
        .to("#hero-ipad", {
          scale: 0.7,
        })
        .to(
          "#main-content",
          {
            scale: 0.7,
          },
          "<"
        )
        .to("#hero-outline-arrow", {
          yPercent: -200,
          duration: 5,
        })
        .to(
          [
            "#hero-sub-heading-one h2",
            "#hero-sub-heading-one h3",
            "#hero-sub-heading-one p",
            "#hero-para-one",
          ],
          {
            autoAlpha: 0,
          },
          "<1"
        )
        .to(
          [
            "#hero-sub-heading-two",
            "#hero-sub-heading-two h2",
            "#hero-sub-heading-two h3",
            "#hero-sub-heading-two p",
            "#hero-para-two",
          ],
          {
            autoAlpha: 1,
            zIndex: 10,
          },
          "<"
        )
        .to(
          "#hero_section",
          {
            height: 0,
            duration: 2,
          },
          "<"
        )
        .set(
          "#hero-ipad-white",
          {
            scale: 0.7,
          },
          ">"
        )
        .to(
          "#hero-ipad",
          {
            scale: 0.7,
          },
          ">"
        )
        .to(
          ".hero_slide_2",
          {
            scale: 0.8,
          },
          "<"
        )
        .to(
          ".hero_slide_1",
          {
            scale: 0.8,
          },
          "<"
        )
        .to(
          "#hero-ipad",
          {
            autoAlpha: 0,
          },
          ">"
        )
        .to(
          "#hero-ipad-white",
          {
            opacity: 1,
          },
          "<"
        )
        .set(
          "#main-content",
          {
            display: "none",
          },
          "<"
        )
        //////////
        .addLabel("button_0")
        .to(
          ".active_button_0",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "<"
        )
        .set(
          "#intro-content",
          {
            display: "block",
          },
          "<20%"
        )
        .fromTo(
          ".left_text_1",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
          },
          "<"
        )
        .to("#hero-large-arrow", {
          top: "68%",
        })
        // .fromTo(
        //   ".card_img_0",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(
          ".side_icon",
          {
            bottom: "10%",
          },
          "-=.5"
        )
        // .fromTo(
        //   ".card_img_0",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_1",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_0", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        .to("#hero-large-arrow", {
          top: "63%",
        })
        ////////
        .addLabel("button_1")
        .to(
          ".active_button_1",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .fromTo(
          ".left_text_1",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          }
        )
        .fromTo(
          ".left_text_2",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
          }
        )
        .to(
          ".side_icon",
          {
            bottom: "30%",
          },
          "-=.5"
        )

        // .fromTo(
        //   ".card_img_1",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_2",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_1", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_2")
        .to(
          ".active_button_2",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "58%",
        })
        .fromTo(
          ".left_text_2",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          }
        )
        .fromTo(
          ".left_text_3",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
          }
        )
        .to(
          ".side_icon",
          {
            bottom: "35%",
          },
          "-=.5"
        )

        // .fromTo(
        //   ".card_img_2",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_3",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_2", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_3")
        .to(".active_button_3", {
          background: "rgba(83, 132, 153, 0.5)",
          rotate: "-3deg",
        })
        .to("#hero-large-arrow", {
          top: "53%",
        })
        .fromTo(
          ".left_text_3",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          }
        )
        .fromTo(
          ".left_text_4",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
          }
        )
        .to(
          ".side_icon",
          {
            bottom: "40%",
          },
          "-=.5"
        )
        // .fromTo(
        //   ".card_img_3",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_4",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_3", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_4")
        .to(
          ".active_button_4",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "48%",
        })
        .fromTo(
          ".left_text_4",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          }
        )
        .fromTo(
          ".left_text_5",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
          }
        )
        .to(
          ".side_icon",
          {
            bottom: "45%",
          },
          "-=.5"
        )
        .to(["#para_text_01 button", "#para_text_01 p"], {
          color: "white",
        })
        .to("#root", {
          backgroundColor: "#29424D",
        });
    });

    mm.add("(min-width: 1299px) and (max-width:1599.98px)", () => {
      gsap.set("#main-content", {
        y: "-60px",
      });
      gsap.set(["#hero-sub-heading-two", "#hero-sub-heading-two h2"], {
        zIndex: "-1",
      });
      gsap.set("#hero-sub-heading-one h2", {
        y: "100px",
      });
      gsap.set(".arrow-hero-one", {
        scale: 0.8,
      });
      gsap.set(".arrow-hero-two", {
        yPercent: -11,
        xPercent: 75,
        scale: 0.3,
      });
      gsap.set(".arrow-hero-three", {
        xPercent: -65,
        yPercent: -22,
        scale: 0.15,
      });
      gsap.set("#hero-large-arrow", {
        left: "50%",
        xPercent: -50,
        top: "70%",
      });
      gsap.set("#intro-content", {
        display: "none",
      });
      gsap.from([".arrow-hero-one", ".arrow-hero-two", ".arrow-hero-three"], {
        y: 500,
        stagger: 0.1,
        duration: 1.5,
        delay: 3.0,
      });

      gsap.set(
        [
          "#hero-sub-heading-one h3",
          "#hero-sub-heading-one p",
          "#hero-sub-heading-two h2",
          "#hero-sub-heading-two h3",
          "#hero-sub-heading-two p",
          "#hero-para-one",
          "#hero-para-two",
        ],
        {
          autoAlpha: 0,
        }
      );

      gsap.set("#hero-outline-arrow", {
        autoAlpha: 0,
        yPercent: 100,
      });

      gsap.set(["#hero-ipad", "#hero-ipad-white"], {
        scale: 2,
        top: "45%",
        left: "50%",
        xPercent: -50,
        yPercent: -50,
      });
      gsap.set("#main-content", {
        y: "-70px",
      });
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#hero",
          start: "top top",
          end: "+=500%",
          pin: true,
          scrub: 1,
          fastScrollEnd: true,
        },
      });

      tl.to(".arrow-hero-one", {
        yPercent: -200,
        autoAlpha: 0,
      })
        .to(
          ".arrow-hero-three",
          {
            yPercent: -300,
            autoAlpha: 0,
          },
          "<"
        )
        .to("#hero-sub-heading-one h2", {
          y: "0px",
        })
        .to(
          ".arrow-hero-two",
          {
            xPercent: 78,
            yPercent: 3,
            scale: 0.1,
            rotate: "45deg",
          },
          "<"
        )
        .set("#hero-outline-arrow", {
          autoAlpha: 1,
        })
        .set(
          "#hero-ipad",
          {
            autoAlpha: 1,
          },
          "<"
        )
        .to(
          [
            "#hero-sub-heading-one",
            "#hero-sub-heading-one h3",
            "#hero-sub-heading-one p",
            "#hero-para-one",
          ],
          {
            autoAlpha: 1,
            zIndex: 10,
          }
        )
        .to(
          [
            "#hero-sub-heading-one h2"
          ],
          {
            scale: 0.8
          }
        )
        .to(
          [
            "#hero-sub-heading-two h2"
          ],
          {
            scale: 0.8
          }
        )
        .to("#hero-ipad", {
          scale: 1,
        })
        .to(
          "#main-content",
          {
            scale: 1,
          },
          "<"
        )
        .to("#hero-outline-arrow", {
          yPercent: -200,
          duration: 5,
        })
        .to(
          [
            "#hero-sub-heading-one h2",
            "#hero-sub-heading-one h3",
            "#hero-sub-heading-one p",
            "#hero-para-one",
          ],
          {
            autoAlpha: 0,
          },
          "<1"
        )
        .to(
          [
            "#hero-sub-heading-two",
            "#hero-sub-heading-two h2",
            "#hero-sub-heading-two h3",
            "#hero-sub-heading-two p",
            "#hero-para-two",
          ],
          {
            autoAlpha: 1,
            zIndex: 10,
          },
          "<"
        )
        .to(
          "#hero_section",
          {
            height: 0,
            duration: 2,
          },
          "<"
        )
        .set(
          "#hero-ipad-white",
          {
            scale: 0.8,
          },
          ">"
        )
        .to(
          "#hero-ipad",
          {
            scale: 0.8,
          },
          ">"
        )
        .to(
          ".hero_slide_2",
          {
            scale: 0.8,
          },
          "<"
        )
        .to(
          ".hero_slide_1",
          {
            scale: 0.8,
          },
          "<"
        )
        .to(
          "#hero-ipad",
          {
            autoAlpha: 0,
          },
          ">"
        )
        .to(
          "#hero-ipad-white",
          {
            opacity: 1,
          },
          "<"
        )
        .set(
          "#main-content",
          {
            display: "none",
          },
          "<"
        )
        //////////
        .addLabel("button_0")
        .to(
          ".active_button_0",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "<"
        )
        .set(
          "#intro-content",
          {
            display: "block",
          },
          "<20%"
        )
        .fromTo(
          ".left_text_1",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
          },
          "<"
        )
        .to("#hero-large-arrow", {
          top: "68%",
        })
        // .fromTo(
        //   ".card_img_0",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(
          ".side_icon",
          {
            bottom: "10%",
          },
          "-=.5"
        )
        // .fromTo(
        //   ".card_img_0",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_1",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_0", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        .to("#hero-large-arrow", {
          top: "63%",
        })
        ////////
        .addLabel("button_1")
        .to(
          ".active_button_1",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .fromTo(
          ".left_text_1",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          }
        )
        .fromTo(
          ".left_text_2",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
          }
        )
        .to(
          ".side_icon",
          {
            bottom: "30%",
          },
          "-=.5"
        )

        // .fromTo(
        //   ".card_img_1",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_2",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_1", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_2")
        .to(
          ".active_button_2",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "58%",
        })
        .fromTo(
          ".left_text_2",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          }
        )
        .fromTo(
          ".left_text_3",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
          }
        )
        .to(
          ".side_icon",
          {
            bottom: "35%",
          },
          "-=.5"
        )

        // .fromTo(
        //   ".card_img_2",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_3",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_2", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_3")
        .to(".active_button_3", {
          background: "rgba(83, 132, 153, 0.5)",
          rotate: "-3deg",
        })
        .to("#hero-large-arrow", {
          top: "53%",
        })
        .fromTo(
          ".left_text_3",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          }
        )
        .fromTo(
          ".left_text_4",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
          }
        )
        .to(
          ".side_icon",
          {
            bottom: "40%",
          },
          "-=.5"
        )
        // .fromTo(
        //   ".card_img_3",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_4",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_3", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_4")
        .to(
          ".active_button_4",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "48%",
        })
        .fromTo(
          ".left_text_4",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          }
        )
        .fromTo(
          ".left_text_5",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
          }
        )
        .to(
          ".side_icon",
          {
            bottom: "45%",
          },
          "-=.5"
        )
        .to(["#para_text_01 button", "#para_text_01 p"], {
          color: "white",
        })
        .to("#root", {
          backgroundColor: "#29424D",
        });
    });

    mm.add("(min-width: 834px) and (max-width:999.98px)", () => {
      gsap.set("#main-content", {
        y: "-60px",
      });
      gsap.set(".arrow-hero-one", {
        scale: 0.8,
      });
      gsap.set(".arrow-hero-two", {
        xPercent: 80,
        yPercent: 48,
        scale: 0.4,
      });
      gsap.set(".arrow-hero-three", {
        xPercent: -54,
        yPercent: -15,
        scale: 0.15,
      });
      gsap.set("#hero-large-arrow", {
        left: "50%",
        xPercent: -50,
        top: "75%",
      });
      gsap.set(["#hero-sub-heading-two", "#hero-sub-heading-two h2"], {
        zIndex: "-1",
      });
      gsap.set("#hero-sub-heading-one h2", {
        y: "100px",
      });
      gsap.from([".arrow-hero-one", ".arrow-hero-two", ".arrow-hero-three"], {
        y: 500,
        stagger: 0.1,
        duration: 1.5,
        delay: 3.0,
      });

      gsap.set(
        [
          "#hero-sub-heading-one h3",
          "#hero-sub-heading-one p",
          "#hero-sub-heading-two h2",
          "#hero-sub-heading-two h3",
          "#hero-sub-heading-two p",
          "#hero-para-one",
          "#hero-para-two",
        ],
        {
          autoAlpha: 0,
        }
      );
      gsap.set("#hero-outline-arrow", {
        autoAlpha: 0,
        yPercent: 100,
      });
      gsap.set(["#hero-ipad", "#hero-ipad-white"], {
        scale: 2,
        top: "45%",
        left: "50%",
        xPercent: -50,
        yPercent: -50,
      });
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#hero",
          start: "top top",
          end: "+=500%",
          pin: true,
          scrub: 1,
          fastScrollEnd: true,
        },
      });

      tl.to(".arrow-hero-one", {
        yPercent: -200,
        autoAlpha: 0,
      })
        .to(
          ".arrow-hero-three",
          {
            yPercent: -300,
            autoAlpha: 0,
          },
          "<"
        )
        .to("#hero-sub-heading-one h2", {
          y: "0px",
        })
        .to(
          ".arrow-hero-two",
          {
            xPercent: 78,
            yPercent: 3,
            scale: 0.1,
            rotate: "45deg",
          },
          "<"
        )
        .set("#hero-outline-arrow", {
          autoAlpha: 1,
        })
        .set(
          "#hero-ipad",
          {
            autoAlpha: 1,
          },
          "<"
        )
        .to(
          [
            "#hero-sub-heading-one",
            "#hero-sub-heading-one h3",
            "#hero-sub-heading-one p",
            "#hero-para-one",
          ],
          {
            autoAlpha: 1,
            zIndex: 10,
          }
        )
        .to("#hero-ipad", {
          scale: 1,
        })
        .to(
          "#main-content",
          {
            scale: 1,
          },
          "<"
        )
        .to("#hero-outline-arrow", {
          yPercent: -200,
          duration: 5,
        })
        .to(
          [
            "#hero-sub-heading-one h2",
            "#hero-sub-heading-one h3",
            "#hero-sub-heading-one p",
            "#hero-para-one",
          ],
          {
            autoAlpha: 0,
          },
          "<1"
        )
        .to(
          [
            "#hero-sub-heading-two",
            "#hero-sub-heading-two h2",
            "#hero-sub-heading-two h3",
            "#hero-sub-heading-two p",
            "#hero-para-two",
          ],
          {
            autoAlpha: 1,
            zIndex: 10,
          },
          "<"
        )
        .to(
          "#hero_section",
          {
            height: 0,
            duration: 2,
          },
          "<"
        )
        .set(
          "#hero-ipad-white",
          {
            scale: 1,
          },
          ">"
        )
        .to(
          [".hero_slide_1", ".hero_slide_2"],
          {
            scale: 0.8,
          },
          ">"
        )
        .to(
          [".hero_slide_2", ".hero_slide_1"],
          {
            top: "20%",
          },
          "<"
        )
        .to(
          "#hero-ipad",
          {
            autoAlpha: 0,
          },
          ">"
        )
        .to(
          "#hero-ipad-white",
          {
            opacity: 1,
          },
          "<"
        )
        .set(
          "#main-content",
          {
            display: "none",
          },
          "<"
        )
        //////////
        .addLabel("button_0")
        .to(
          ".active_button_0",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "<"
        )
        .set(
          "#intro-content",
          {
            display: "block",
          },
          "<20%"
        )
        .to("#hero-large-arrow", {
          top: "73%",
        })
        // .fromTo(
        //   ".card_img_0",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        // .fromTo(
        //   ".card_img_0",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_1",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_0", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_1")
        .to(
          ".active_button_1",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "70%",
        })
        .fromTo(
          ".left_text_1",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          }
        )

        // .fromTo(
        //   ".card_img_1",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_2",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_1", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_2")
        .to(
          ".active_button_2",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "68%",
        })

        // .fromTo(
        //   ".card_img_2",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_3",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_2", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_3")
        .to(".active_button_3", {
          background: "rgba(83, 132, 153, 0.5)",
          rotate: "-3deg",
        })
        .to("#hero-large-arrow", {
          top: "66%",
        })
        .fromTo(
          ".left_text_3",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          }
        )
        // .fromTo(
        //   ".card_img_3",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_4",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_3", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_4")
        .to(
          ".active_button_4",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "64%",
        })
        .to(["#para_text_01 button", "#para_text_01 p"], {
          color: "white",
        })
        .to("#root", {
          backgroundColor: "#29424D",
        });
    });

    mm.add("(min-width: 576px) and (max-width:833px)", () => {
      gsap.set("#main-content", {
        y: "-60px",
      });
      gsap.set(".arrow-hero-one", {
        scale: 0.8,
      });
      gsap.set(".arrow-hero-two", {
        xPercent: 80,
        yPercent: 48,
        scale: 0.4,
      });
      gsap.set(".arrow-hero-three", {
        xPercent: -54,
        yPercent: -15,
        scale: 0.15,
      });
      gsap.set("#hero-large-arrow", {
        left: "50%",
        xPercent: -50,
        top: "75%",
      });
      gsap.set(["#hero-sub-heading-two", "#hero-sub-heading-two h2"], {
        zIndex: "-1",
      });
      gsap.set("#hero-sub-heading-one h2", {
        y: "100px",
      });
      gsap.from([".arrow-hero-one", ".arrow-hero-two", ".arrow-hero-three"], {
        y: 500,
        stagger: 0.1,
        duration: 1.5,
        delay: 3.0,
      });

      gsap.set(
        [
          "#hero-sub-heading-one h3",
          "#hero-sub-heading-one p",
          "#hero-sub-heading-two h2",
          "#hero-sub-heading-two h3",
          "#hero-sub-heading-two p",
          "#hero-para-one",
          "#hero-para-two",
        ],
        {
          autoAlpha: 0,
        }
      );
      gsap.set("#hero-outline-arrow", {
        autoAlpha: 0,
        yPercent: 100,
      });
      gsap.set(["#hero-ipad", "#hero-ipad-white"], {
        scale: 2,
        top: "45%",
        left: "50%",
        xPercent: -50,
        yPercent: -50,
      });
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#hero",
          start: "top top",
          end: "+=500%",
          pin: true,
          scrub: 1,
          fastScrollEnd: true,
        },
      });

      tl.to(".arrow-hero-one", {
        yPercent: -200,
        autoAlpha: 0,
      })
        .to(
          ".arrow-hero-three",
          {
            yPercent: -300,
            autoAlpha: 0,
          },
          "<"
        )
        .to("#hero-sub-heading-one h2", {
          y: "0px",
        })
        .to(
          ".arrow-hero-two",
          {
            xPercent: 78,
            yPercent: 3,
            scale: 0.1,
            rotate: "45deg",
          },
          "<"
        )
        .set("#hero-outline-arrow", {
          autoAlpha: 1,
        })
        .set(
          "#hero-ipad",
          {
            autoAlpha: 1,
          },
          "<"
        )
        .to(
          [
            "#hero-sub-heading-one",
            "#hero-sub-heading-one h3",
            "#hero-sub-heading-one p",
            "#hero-para-one",
          ],
          {
            autoAlpha: 1,
            zIndex: 10,
          }
        )
        .to("#hero-ipad", {
          scale: 1,
        })
        .to(
          "#main-content",
          {
            scale: 1,
          },
          "<"
        )
        .to("#hero-outline-arrow", {
          yPercent: -200,
          duration: 5,
        })
        .to(
          [
            "#hero-sub-heading-one h2",
            "#hero-sub-heading-one h3",
            "#hero-sub-heading-one p",
            "#hero-para-one",
          ],
          {
            autoAlpha: 0,
          },
          "<1"
        )
        .to(
          [
            "#hero-sub-heading-two",
            "#hero-sub-heading-two h2",
            "#hero-sub-heading-two h3",
            "#hero-sub-heading-two p",
            "#hero-para-two",
          ],
          {
            autoAlpha: 1,
            zIndex: 10,
          },
          "<"
        )
        .to(
          "#hero_section",
          {
            height: 0,
            duration: 2,
          },
          "<"
        )
        .set(
          "#hero-ipad-white",
          {
            scale: 0.8,
            top: "35%",
          },
          ">"
        )
        .to(
          ["#hero-ipad", ".hero_slide_1", ".hero_slide_2"],
          {
            scale: 0.8,
          },
          ">"
        )
        .to(
          "#hero-ipad",
          {
            top: "35%",
          },
          "<"
        )
        .to(
          [".hero_slide_2", ".hero_slide_1"],
          {
            top: "20%",
          },
          "<"
        )
        .to(
          "#hero-ipad",
          {
            autoAlpha: 0,
          },
          ">"
        )
        .to(
          "#hero-ipad-white",
          {
            opacity: 1,
          },
          "<"
        )
        .set(
          "#main-content",
          {
            display: "none",
          },
          "<"
        )
        //////////
        .addLabel("button_0")
        .to(
          ".active_button_0",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "<"
        )
        .set(
          "#intro-content",
          {
            display: "block",
          },
          "<20%"
        )
        .to("#hero-large-arrow", {
          top: "73%",
        })
        // .fromTo(
        //   ".card_img_0",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        // .fromTo(
        //   ".card_img_0",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_1",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_0", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_1")
        .to(
          ".active_button_1",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "70%",
        })
        .fromTo(
          ".left_text_1",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          }
        )

        // .fromTo(
        //   ".card_img_1",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_2",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_1", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_2")
        .to(
          ".active_button_2",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "68%",
        })

        // .fromTo(
        //   ".card_img_2",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_3",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_2", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_3")
        .to(".active_button_3", {
          background: "rgba(83, 132, 153, 0.5)",
          rotate: "-3deg",
        })
        .to("#hero-large-arrow", {
          top: "66%",
        })
        .fromTo(
          ".left_text_3",
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
          }
        )
        // .fromTo(
        //   ".card_img_3",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_4",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_3", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_4")
        .to(
          ".active_button_4",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "64%",
        })
        .to(["#para_text_01 button", "#para_text_01 p"], {
          color: "white",
        })
        .to("#root", {
          backgroundColor: "#29424D",
        });
    });

    mm.add("(max-width:575.98px)", () => {
      gsap.set("#main-content", {
        // y: "-145px",
        marginLeft: "calc(var(--bs-gutter-x) * -0.5)",
        marginRight: "calc(var(--bs-gutter-x) * -0.5)"
      });
      gsap.set(".arrow-hero-one", {
        scale: 0.5,
      });
      gsap.set(".arrow-hero-two", {
        xPercent: 29,
        yPercent: -24,
        scale: 0.15,
      });
      gsap.set(".arrow-hero-three", {
        xPercent: -24,
        yPercent: 37,
        scale: 0.12,
      });
      gsap.set("#hero-large-arrow", {
        left: "50%",
        xPercent: -50,
        top: "75%",
      });
      gsap.set("#hero-sub-heading-one h2", {
        y: "80px",
      });
      gsap.from([".arrow-hero-one", ".arrow-hero-two", ".arrow-hero-three"], {
        y: 500,
        stagger: 0.1,
        duration: 1.5,
        delay: 3.0,
      });

      gsap.set(
        [
          "#hero-sub-heading-one h3",
          "#hero-sub-heading-one p",
          "#hero-sub-heading-two",
          "#hero-para-one",
          "#hero-para-two",
        ],
        {
          autoAlpha: 0,
        }
      );
      gsap.set("#hero-outline-arrow", {
        autoAlpha: 0,
        yPercent: 100,
      });

      gsap.set(["#hero-ipad", "#hero-ipad-white"], {
        scale: 2,
        top: "45%",
        left: "50%",
        xPercent: -50,
        yPercent: -44,
      });
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#hero",
          start: "top top",
          end: "+=500%",
          pin: true,
          scrub: 1,
          fastScrollEnd: true,
        },
      });

      tl.to(".arrow-hero-one", {
        yPercent: -200,
        autoAlpha: 0,
      })
        .to(
          ".arrow-hero-three",
          {
            yPercent: -300,
            autoAlpha: 0,
          },
          "<"
        )
        .to("#hero-sub-heading-one h2", {
          y: "0px",
        })
        .to(
          ".arrow-hero-two",
          {
            xPercent: 53,
            yPercent: 3,
            scale: 0.1,
            rotate: "45deg",
          },
          "<"
        )
        .set("#hero-outline-arrow", {
          autoAlpha: 1,
        })
        .set(
          "#hero-ipad",
          {
            autoAlpha: 1,
          },
          "<"
        )
        .to(
          [
            "#hero-sub-heading-one h3",
            "#hero-sub-heading-one p",
            "#hero-para-one",
          ],
          {
            autoAlpha: 1,
          }
        )
        .to("#hero-ipad", {
          scale: 1,
        })
        .set(
          "#hero-ipad",
          {
            scale: 0.98,
          },
          ">"
        )
        .to(
          "#main-content",
          {
            scale: 1,
          },
          "<"
        )
        .to("#hero-outline-arrow", {
          yPercent: -200,
          duration: 5,
        })
        .to(
          [
            "#hero-sub-heading-one h2",
            "#hero-sub-heading-one h3",
            "#hero-sub-heading-one p",
            "#hero-para-one",
          ],
          {
            autoAlpha: 0,
          },
          "<1"
        )
        .to(
          ["#hero-sub-heading-two", "#hero-para-two"],
          {
            autoAlpha: 1,
          },
          "<"
        )
        .to(
          "#hero_section",
          {
            height: 0,
            duration: 4,
          },
          "<"
        )
        .set(
          "#hero-ipad-white",
          {
            scale: 0.98,
          },
          ">"
        )
        .to(
          "#hero-ipad",
          {
            autoAlpha: 0,
          },
          ">"
        )
        .to(
          "#hero-ipad-white",
          {
            opacity: 1,
          },
          "<"
        )
        .set(
          "#main-content",
          {
            display: "none",
          },
          "<"
        )
        .set(
          "#intro-content",
          {
            display: "block",
          },
          "<20%"
        )
        //////////
        .addLabel("button_0")
        .to(
          ".active_button_0",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "73%",
        })
        // .fromTo(
        //   ".card_img_0",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        // .fromTo(
        //   ".card_img_0",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_1",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_0", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_1")
        .to(
          ".active_button_1",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "70%",
        })
        // .fromTo(
        //   ".card_img_1",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_2",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_1", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_2")
        .to(
          ".active_button_2",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "68%",
        })
        // .fromTo(
        //   ".card_img_2",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_3",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_2", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_3")
        .to(".active_button_3", {
          background: "rgba(83, 132, 153, 0.5)",
          rotate: "-3deg",
        })
        .to("#hero-large-arrow", {
          top: "66%",
        })
        // .fromTo(
        //   ".card_img_3",
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //   }
        // )
        // .fromTo(
        //   ".card_img_4",
        //   {
        //     top: "50px",
        //   },
        //   {
        //     opacity: 1,
        //     top: "0px",
        //   }
        // )
        .to(".active_button_3", {
          background: "rgba(126, 228, 156, 0.5)",
          rotate: 0,
        })
        //////////
        .addLabel("button_4")
        .to(
          ".active_button_4",
          {
            background: "rgba(83, 132, 153, 0.5)",
            rotate: "-3deg",
          },
          "-=.5"
        )
        .to("#hero-large-arrow", {
          top: "64%",
        })
        .to(["#para_text_01 button", "#para_text_01 p"], {
          color: "white",
        })
        .to("#root", {
          backgroundColor: "#29424D",
        });
    });
  }, []);

  const {
    buttonData: buttonDataLocale,
    buttonDataMobile: buttonDataMobileLocale,
    Header: headerLocale
  } = Hero;

  const buttonData = [
    {
      buttonText: "MattIA",
      buttonId: "button_0",
      card_img: comeLottie,
      para: buttonDataLocale[0].para,
    },
    {
      buttonText: buttonDataLocale[1].buttonText,
      buttonId: "button_1",
      card_img: marketingLottie,
      para: buttonDataLocale[1].para,
    },
    {
      buttonText: buttonDataLocale[2].buttonText,
      buttonId: "button_2",
      card_img: techDrivenLottie,
      para: buttonDataLocale[2].para,
    },
    {
      buttonText: buttonDataLocale[3].buttonText,
      buttonId: "button_3",
      card_img: realTimeLottie,
      para: buttonDataLocale[3].para
    },
    {
      buttonText: buttonDataLocale[4].buttonText,
      buttonId: "button_4",
      card_img: crmLottie,
      para: buttonDataLocale[4].para
    }
  ];

  const buttonDataMobile = [
    {
      buttonText: "MattIA",
      buttonId: "button_0",
      card_img: comeLottie,
      para: buttonDataMobileLocale[0].para,
      after: buttonDataMobileLocale[0].after
    },
    {
      buttonText: buttonDataMobileLocale[1].buttonText,
      buttonId: "button_1",
      card_img: marketingLottie
    },
    {
      buttonText: buttonDataMobileLocale[2].buttonText,
      buttonId: "button_2",
      card_img: techDrivenLottie,
      para: buttonDataMobileLocale[2].para,
    },
    {
      buttonText: buttonDataMobileLocale[3].buttonText,
      buttonId: "button_3",
      card_img: realTimeLottie,
      para: buttonDataMobileLocale[3].para
    },
    {
      buttonText: buttonDataMobileLocale[4].buttonText,
      buttonId: "button_4",
      card_img: crmLottie,
      para: buttonDataMobileLocale[4].para
    }
  ];

  const venditeItbtn2 = () => {
    if (window.innerWidth > 1600) {
      gsap.to(window, { duration: 0.3, scrollTo: 2500, ease: "linear" });
    }
    if (window.innerWidth > 1600 && window.innerHeight <= 1025) {
      gsap.to(window, { duration: 0.3, scrollTo: 2000, ease: "linear" });
    }
    if (window.innerWidth < 1599) {
      gsap.to(window, { duration: 0.3, scrollTo: 1500, ease: "linear" });
    }
    if (window.innerWidth < 1200) {
      gsap.to(window, { duration: 0.3, scrollTo: 1200, ease: "linear" });
    }
    if (window.innerWidth < 992) {
      gsap.to(window, { duration: 0.3, scrollTo: 2000, ease: "linear" });
    }
    if (window.innerWidth < 769 && window.innerHeight <= 1025) {
      gsap.to(window, { duration: 0.3, scrollTo: 2000, ease: "linear" });
    }
    if (window.innerWidth < 576 && window.innerHeight) {
      gsap.to(window, { duration: 0.3, scrollTo: 3000, ease: "linear" });
    }
  };
  const moveToIt = (id) => {
    ////////// 1
    if (id === 0 && window.innerWidth > 2000) {
      gsap.to(window, { duration: 0.3, scrollTo: 2800, ease: "linear" });
    }
    if (id === 0 && window.innerWidth > 1600 && window.innerHeight <= 1090) {
      gsap.to(window, { duration: 0.3, scrollTo: 2000, ease: "linear" });
    }
    if (id === 0 && window.innerWidth > 1600 && window.innerHeight <= 1025) {
      gsap.to(window, { duration: 0.3, scrollTo: 1800, ease: "linear" });
    }
    if (id === 0 && window.innerWidth < 1599) {
      gsap.to(window, { duration: 0.3, scrollTo: 1500, ease: "linear" });
    }
    if (id === 0 && window.innerWidth < 1100) {
      gsap.to(window, { duration: 0.3, scrollTo: 1200, ease: "linear" });
    }
    if (id === 0 && window.innerWidth <= 1024) {
      gsap.to(window, { duration: 0.3, scrollTo: 1900, ease: "linear" });
    }
    if (id === 0 && window.innerWidth < 992) {
      gsap.to(window, { duration: 0.3, scrollTo: 2000, ease: "linear" });
    }
    if (id === 0 && window.innerWidth < 576) {
      gsap.to(window, { duration: 0.3, scrollTo: 1200, ease: "linear" });
    }

    ////////// 2
    if (id === 1 && window.innerWidth > 2000) {
      gsap.to(window, { duration: 0.3, scrollTo: 4000, ease: "linear" });
    }
    if (id === 1 && window.innerWidth > 1600 && window.innerHeight <= 1000) {
      gsap.to(window, { duration: 0.3, scrollTo: 2500, ease: "linear" });
    }else if (id === 1 && window.innerWidth > 1600 && window.innerHeight <= 1090) {
      gsap.to(window, { duration: 0.3, scrollTo: 2800, ease: "linear" });
    }
    if (id === 1 && window.innerWidth > 1600 && window.innerHeight <= 1025) {
      //gsap.to(window, { duration: 0.3, scrollTo: 2500, ease: "linear" });
    }
    if (id === 1 && window.innerWidth < 1599) {
      gsap.to(window, { duration: 0.3, scrollTo: 2000, ease: "linear" });
    }
    if (id === 1 && window.innerWidth < 1100) {
      gsap.to(window, { duration: 0.3, scrollTo: 1600, ease: "linear" });
    }
    if (id === 1 && window.innerWidth <= 1024) {
      gsap.to(window, { duration: 0.3, scrollTo: 3000, ease: "linear" });
    }
    if (id === 1 && window.innerWidth < 992) {
      gsap.to(window, { duration: 0.3, scrollTo: 3000, ease: "linear" });
    }
    if (id === 1 && window.innerWidth < 769) {
      gsap.to(window, { duration: 0.3, scrollTo: 3200, ease: "linear" });
    }
    if (id === 1 && window.innerWidth < 576) {
      gsap.to(window, { duration: 0.3, scrollTo: 1600, ease: "linear" });
    }

    ////////// 3
    if (id === 2 && window.innerWidth > 2000) {
      gsap.to(window, { duration: 0.3, scrollTo: 4900, ease: "linear" });
    }
    if (id === 2 && window.innerWidth > 1600 && window.innerHeight <= 1090) {
      gsap.to(window, { duration: 0.3, scrollTo: 3500, ease: "linear" });
    }
    if (id === 2 && window.innerWidth > 1600 && window.innerHeight <= 1025) {
      //gsap.to(window, { duration: 0.3, scrollTo: 3450, ease: "linear" });
    }
    if (id === 2 && window.innerWidth < 1599) {
      gsap.to(window, { duration: 0.3, scrollTo: 2500, ease: "linear" });
    }
    if (id === 2 && window.innerWidth < 1100) {
      gsap.to(window, { duration: 0.3, scrollTo: 2050, ease: "linear" });
    }
    if (id === 2 && window.innerWidth <= 1024) {
      gsap.to(window, { duration: 0.3, scrollTo: 4000, ease: "linear" });
    }
    if (id === 2 && window.innerWidth < 992) {
      gsap.to(window, { duration: 0.3, scrollTo: 3900, ease: "linear" });
    }
    if (id === 2 && window.innerWidth < 769) {
      gsap.to(window, { duration: 0.3, scrollTo: 3500, ease: "linear" });
    }
    if (id === 2 && window.innerWidth < 576) {
      gsap.to(window, { duration: 0.3, scrollTo: 2300, ease: "linear" });
    }

    ////////// 4
    if (id === 3 && window.innerWidth > 2000) {
      gsap.to(window, { duration: 0.3, scrollTo: 6000, ease: "linear" });
    }
    if (id === 3 && window.innerWidth > 1600 && window.innerHeight <= 1090) {
      gsap.to(window, { duration: 0.3, scrollTo: 4300, ease: "linear" });
    }
    if (id === 3 && window.innerWidth > 1600 && window.innerHeight <= 1025) {
      gsap.to(window, { duration: 0.3, scrollTo: 4000, ease: "linear" });
    }
    if (id === 3 && window.innerWidth < 1599) {
      gsap.to(window, { duration: 0.3, scrollTo: 3000, ease: "linear" });
    }
    if (id === 3 && window.innerWidth < 1100) {
      gsap.to(window, { duration: 0.3, scrollTo: 2320, ease: "linear" });
    }
    if (id === 3 && window.innerWidth <= 1024) {
      gsap.to(window, { duration: 0.3, scrollTo: 5000, ease: "linear" });
    }
    if (id === 3 && window.innerWidth < 992) {
      gsap.to(window, { duration: 0.3, scrollTo: 5200, ease: "linear" });
    }
    if (id === 3 && window.innerWidth < 769) {
      gsap.to(window, { duration: 0.3, scrollTo: 4000, ease: "linear" });
    }
    if (id === 3 && window.innerWidth < 576) {
      gsap.to(window, { duration: 0.3, scrollTo: 2600, ease: "linear" });
    }

    ////////// 5
    if (id === 4 && window.innerWidth > 2000) {
      gsap.to(window, { duration: 0.3, scrollTo: 6800, ease: "linear" });
    }
    if (id === 4 && window.innerWidth > 1600 && window.innerHeight <= 1090) {
      gsap.to(window, { duration: 0.3, scrollTo: 5000, ease: "linear" });
    }
    if (id === 4 && window.innerWidth > 1600 && window.innerHeight <= 1025) {
      gsap.to(window, { duration: 0.3, scrollTo: 4700, ease: "linear" });
    }
    if (id === 4 && window.innerWidth < 1599) {
      gsap.to(window, { duration: 0.3, scrollTo: 3600, ease: "linear" });
    }
    if (id === 4 && window.innerWidth < 1100) {
      gsap.to(window, { duration: 0.3, scrollTo: 2820, ease: "linear" });
    }
    if (id === 4 && window.innerWidth <= 1024) {
      gsap.to(window, { duration: 0.3, scrollTo: 6000, ease: "linear" });
    }
    if (id === 4 && window.innerWidth < 992) {
      gsap.to(window, { duration: 0.3, scrollTo: 6000, ease: "linear" });
    }
    if (id === 4 && window.innerWidth < 769) {
      gsap.to(window, { duration: 0.3, scrollTo: 4700, ease: "linear" });
    }
    if (id === 4 && window.innerWidth < 576) {
      gsap.to(window, { duration: 0.3, scrollTo: 3200, ease: "linear" });
    }
  };

  console.log(window.innerWidth + ' x ' + window.innerHeight);

  return (
    <>
      <div className="position-relative z-[2147483001]">
        <Header locale={headerLocale} />
        <div id="hero" className="color_dark_gray z-10 overflow-hidden vh-100">
          <div className="container vh-100 z-0 position-relative hero_custom_container">
            <div
              id="main-content"
              className="align-items-center flex-column justify-content-center position-relative h-100"
            >
              <div className="cursor-pointer">
                <LottieHero />
              </div>
              <div
                id="hero-sub-heading"
                className="w-100 d-flex flex-column justify-content-center hero_section_text align-items-center text-white"
              >
                <div
                  className="hero_slide_1 pt-xl-5 mt5"
                  id="hero-sub-heading-one"
                >
                  <h2 className="align-items-center justify-content-center flex-wrap font_6xl mb-0 text-center z_1 position-relative">
                    {Hero.phrase1}
                    <a
                      className="d-inline-block text-decoration-none mt-sm-2 mt-xxl-0"
                      href="#hero-sub-heading-two"
                    >
                      <button
                        className="custom_tab_button_012 cursor-pointer d-flex align-items-center justify-content-center hero_heading_btn hero_slide_3_btn me-2 mx-sm-2 mx-lg-3"
                        onClick={() => venditeItbtn2()}
                      >
                        {Hero.phrase2}
                      </button>
                    </a>
                    <br className="d-block d-xxl-none"></br>
                    {Hero.phrase3}
                    {' '}
                    <br className="d-none d-xxl-block"></br>
                    {Hero.phrase3b}<br />
                    {Hero.phrase4}
                  </h2>
                    <h3 className="font_8xl fw-bold number_heading py-3 py-xl-4 text-center color_light_green ff_poppins">
                      +25%
                    </h3>
                  <p className="font_xl font_4xl color_white_off text-center pb-xl-4 hero_slide_para_1">
                  {Hero.phrase5}
                  </p>
                  <p className="custom_lh_132 font_xl color_white_off text-center pt-3 pt-md-5 pt-lg-2 pt-xl-0 pb-xl-4 hero_slide_para_2">
                    {Hero.phrase6}<br />
                    {Hero.phrase7}<br />
                    {Hero.phrase8}
                  </p>
                </div>
                {/* ============================================================================== */}
                <div
                  className="hero_slide_2 pt-xl-5 mt5"
                  id="hero-sub-heading-two"
                >
                  <h2 className="d-flex align-items-center justify-content-center flex-wrap font_6xl mb-0 text-center hero_text_2 z_1 position-relative">
                    {Hero.phrase9}<br />
                    {Hero.phrase10}
                  </h2>
                  <h3 className="font_8xl fw-bold number_heading py-3 py-xl-4 text-center color_light_green opacity0 ff_poppins">
                    +50%
                  </h3>
                  <p className="font_xl font_4xl color_white_off text-center pb-xl-4 hero_slide_para_1">
                    {Hero.phrase11}
                  </p>
                  <p className="font_xl color_white_off text-center pb-xl-4 hero_slide_para_1">
                    {Hero.phrase12}<br />
                    {Hero.phrase13}<br />
                    {Hero.phrase14}
                    <br />
                  </p>
                </div>
              </div>

              <div
                id="hero-outline-arrow"
                className="position-absolute z-10 pointer-events-none hero-outline-arrow"
              >
                <Icon4 />
              </div>

              <img
                className="arrow-hero-one position-absolute -z-10 pointer-events-none mt-lg-3 mt-xl-0"
                src={arrow}
                alt="arrow"
              />
              <img
                className="arrow-hero-two position-absolute -z-10 pointer-events-none"
                src={arrow}
                alt="arrow"
              />
              <img
                className="arrow-hero-three position-absolute -z-10 pointer-events-none"
                src={arrow}
                alt="arrow"
              />
            </div>

            <div id="intro-content" className="position-relative h-100 w-100">
              <div
                className="vh-100 d-flex flex-column justify-content-center tab_parent z_2"
                id="main-assets"
              >
                {/* ===== BREADCRUMBS STARTS ===== */}
                <div className="side_icon position-absolute tab_right_icon d-none d-lg-block me-4">
                  <div className="d-flex align-items-center tab-side-effect left_text_5">
                    <p className="me-4 color_light_green mb-0">
                      {Hero.discoverMattia}
                    </p>
                    <TabRightIcon />
                  </div>
                  <div className="d-flex align-items-center tab-side-effect left_text_4">
                    <p className="me-4 color_light_green mb-0">
                      {Hero.discoverMattia}
                    </p>
                    <TabRightIcon />
                  </div>
                  <div className="d-flex align-items-center tab-side-effect left_text_3">
                    <p className="me-4 color_light_green mb-0">
                      {Hero.discoverMattia}
                    </p>
                    <TabRightIcon />
                  </div>
                  <div className="d-flex align-items-center tab-side-effect left_text_2">
                    <p className="me-4 color_light_green mb-0">
                      {Hero.discoverMattia}
                    </p>
                    <TabRightIcon />
                  </div>
                  <div className="d-flex align-items-center tab-side-effect left_text_1">
                    <p className="me-4 color_light_green mb-0">
                      {Hero.discoverMattia}
                    </p>
                    <TabRightIcon />
                  </div>
                </div>
                {/* ===== BREADCRUMBS ENDS ===== */}

                <div className="max_width_tabs position-relative">
                  <div className="text-center pb-sm-5 tabs_parent h-100 d-flex flex-column">
                    <div className="intro_tab_frame my-4"></div>

                    {/* video */}
                    <div className="card_img_0">
                      <div className="video-responsive-container mx-auto start-0 w-100 pt-3 custom_height_tab_img tabs_img pt-4 pt-sm-0">
                        <div className="video-responsive-frame">
                          <video autoPlay muted playsInline loop>
                            <source src={videoWebmV} type="video/webm" />
                          </video>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex">
                      <div className="max_width_tabs d-flex justify-content-center tabs_wrap pt-sm-5 pt-lg-0 tabs_content_parent" style={{marginTop: "8vh"}}>
                        {buttonData.map((obj, index) => (
                          <div className="px-0 px-sm-2 pt-sm-2" key={index}>
                            <div className="d-block">
                              <div
                                className="custom_content_size tab_text_position d-flex align-items-center pt-sm-3 pt-lg-0"
                                id="para_text_01"
                              >
                                <p className="font_2xl color_gray pt-lg-3 mb-0 mb-md-1 mb-lg-3">
                                  <span className="d-none d-sm-block">
                                    {obj.para}
                                  </span>
                                  <span className="d-block d-sm-none">
                                    {buttonDataMobile[index].para}
                                  </span>
                                </p>
                                {(obj.buttonText || buttonDataMobile[index].buttonText) &&
                                  (
                                    <>
                                      <button
                                        className={`custom_tab_button_012 active_button_${index} tabs_btn mx-2 mx-sm-3 font_2xl color_gray d-none d-sm-block`}
                                        dangerouslySetInnerHTML={{
                                          __html: obj.buttonText,
                                        }}
                                        onClick={() => moveToIt(index)}
                                      ></button>
                                      <button
                                        className={`custom_tab_button_012 active_button_${index} tabs_btn mx-2 mx-sm-3 font_2xl color_gray d-block d-sm-none`}
                                        dangerouslySetInnerHTML={{
                                          __html: buttonDataMobile[index].buttonText,
                                        }}
                                        onClick={() => moveToIt(index)}
                                      ></button>
                                    </>
                                  )
                                }
                                {(obj.after || buttonDataMobile[index].after) && <p className="font_2xl color_gray pt-lg-3 mb-0 mb-md-1 mb-lg-3 ms-sm-3">
                                  <span className="d-none d-sm-block">
                                    {obj.after}
                                  </span>
                                  <span className="d-block d-sm-none">
                                    {buttonDataMobile[index].after}
                                  </span>
                                </p>}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <img
              id="hero-ipad"
              alt="hero-ipad"
              className="position-absolute -z-10 pointer-events-none origin-center ipad_img d-none d-sm-block for-desktop-only"
              src={ipad}
            />
            {/* <img
              id="hero-ipad-white"
              alt="hero-ipad-white"
              className="position-absolute -z-10 pointer-events-none origin-center ipad_img opacity_0 d-none d-sm-block for-desktop-only"
              src={ipadGreen}
            /> */}

            {/* <img
              id="hero-ipad"
              alt="hero-ipad"
              className="position-absolute -z-10 pointer-events-none origin-center ipad_img d-sm-none for-tablets-too"
              src={ipadGreenMob}
            />
            <img
              id="hero-ipad-white"
              alt="hero-ipad-white"
              className="position-absolute -z-10 pointer-events-none origin-center ipad_img opacity_0 d-sm-none for-tablets-too"
              src={ipadWhiteMob}
            /> */}
          </div>
          <div
            id="hero_section"
            className="bg_dark_gray position-absolute inset-0 -z-10"
          ></div>
        </div>
      </div>
    </>
  );
};

export default Hero;
