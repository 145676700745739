import React from "react";
import footer_logo from "../assets/images/svg/footer_logo.svg";
import smsImg from "../assets/images/svg/sms_img.svg";
import iconImg from "../assets/images/svg/icon.svg";
import footerbg from "../assets/images/png/footer_bg.png";
import footerMobilebg from "../assets/images/png/footer-bg-mobile-sc.png";
import BackToTop from "./BackToTopButton";
import LogoCarplanner from "../assets/images/png/carplanner-logo.png";
import LogoIdeal from "../assets/images/png/ideal-rent-logo-inverted.png";

function Footer({locale: {Footer}}) {
  return (
    <>
      <div className="footer_top_space">
        <div className="position-relative z_2 manager_content meeting_fissa_top_space">
          <p className="text-center font_3xl pb-0 mb-0 pt-4 d-xs-block d-md-none color_white_off">
            {Footer.mobile.phrase1}<br />
            {Footer.mobile.phrase2}<br />
            {Footer.mobile.phrase3}
          </p>
          <p className="text-center font_3xl pb-4 d-none d-md-block">
            {Footer.desktop.phrase1}<br />
            {Footer.desktop.phrase2}
          </p>
          <div className="d-flex align-items-center justify-content-center meeting_btn_parent meeting_fissa_top_space">
            <a
              className="custom_tab_button_012 cursor-pointer common_button mt-5 mt-sm-0 fissa_bg"
              href="mailto:hello@daicargroup.com"
              target="_blank"
              rel="noreferrer"
            >
              {Footer.phrase1}
            </a>
          </div>
        </div>

        <div className="pt-xl-4 pt-xxl-5 position-relative z_2 manager_content meeting_fissa_top_space">
          <div className="text-center gap-1 gap-sm-3 mt-5 align-items-center fissa-meeting-content-parent">
            <p className="mb-0 font_xl meeting_text_color">
              <span className="opacity_09">
                {Footer.phrase2}
              </span>
            </p>
            <p className="other-projects-list">
              <a href="https://www.carplanner.com">
                <img
                  src={LogoCarplanner}
                  alt="Carplanner"
                />
              </a>
              <a href="https://ideal-rent.com/it/">
                <img
                  src={LogoIdeal}
                  alt="iDeal Rent"
                />
              </a>
            </p>
          </div>
        </div>

        <div
          className="pt-5 position-relative d-flex align-items-sm-end justify-content-sm-center"
          id="footer"
        >
          {/* BACKTOTOP ICON */}
          <BackToTop />
          <img
            className="position-absolute footer_background_set d-sm-none"
            src={footerMobilebg}
            alt="footerMobilebg"
          />
          <img
            className="position-absolute footer_background_set d-none d-sm-block"
            src={footerbg}
            alt="footerbg"
          />
          <div className="container position-relative z_2 pb-5 pt-lg-5 pt-xl-0">
            <div className="row justify-content-md-center justify-content-between gap-lg- text-center text-sm-start">
              <div className="col-12 col-sm-4 col-lg-3 d-flex flex-column justify-content-between">
                <a href="/">
                  <img
                    className="footer_logo"
                    src={footer_logo}
                    alt="footer_logo"
                  />
                </a>
                <div className="mt-4 mt-sm-0 d-none d-md-block">
                  <p className="font_xsm fw_medium ff_montserrat color_white_off pt-3 pt-lg-5">
                    {Footer.phrase3}<br />
                    {Footer.phrase4}
                  </p>
                  <p className="mb-0 font_xsm fw_medium color_light_gray mt-1 ff_montserrat">
                    Daicar Srl P.IVA 14570951005
                  </p>
                  <p className="mb-0 font_xsm fw_medium color_light_gray mt-1 ff_montserrat">
                    Designed & developed<br className="d-md-block d-none"></br>
                    <a
                      href="https://www.alfatauristudio.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="color_light_gray list_item_text"
                    >
                      alfatauristudio.com
                    </a>
                  </p>
                </div>
              </div>

              <div className="col-7 col-md-3 col-lg-2 pe-0 pe-sm-2 ps-0 mx-auto mx-sm-0">
                <ul className="ps-sm-0 text-start mb-5 mb-sm-0 p pb-sm-0 text-nowrap px-2 px-sm-0 mx-auto mx-sm-0">
                  <li className="list-unstyled">
                    <a
                      className="font_xsm text-decoration-none fw_medium color_white_off list_item_text ff_montserrat"
                      href="/cookie-policy.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Cookie Policy
                    </a>
                  </li>
                  <li className="ps-3 ps-sm-0 mt-3 list-unstyled">
                    <a
                      className="font_xsm text-decoration-none fw_medium color_white_off list_item_text ff_montserrat"
                      href="/privacy-policy.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-12 col-sm-4 col-md-3 mt-sm-0 ps-0 ps-sm-2 d-none d-md-block pt-md-3">
                <div className="d-flex align-items-center footer_icons_hover">
                  <img className="contact_icon" src={smsImg} alt="smsImg" />
                  <div className="flex-column d-flex d-inline-block ms-3 ps-3">
                    <a
                      className="mb-0 font_sm fw-normal list-unstyled text-decoration-none color_white_off ff_roboto list_item_text mt-sm-1"
                      href="mailTo:hello@daicargroup.com"
                    >
                      hello@daicargroup.com
                    </a>
                  </div>
                </div>

                <div className="d-flex align-items-center footer_icons_hover custom_margin_footer">
                  <div className="location_icon">
                    <img className="contact_icon" src={iconImg} alt="iconImg" />
                  </div>
                  <div className="flex-column d-flex d-inline-block ms-3 ps-3">
                    <a
                      className="mb-0 font_sm fw-normal list-unstyled text-decoration-none color_white_off ff_roboto list_item_text mt-sm-1"
                      href="https://goo.gl/maps/MJVtXiivME2gYQAp9"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Via Del Porto Fluviale, 1/C{" "}
                      <br className="d-none d-xl-block" />
                      00154 ROMA
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
