import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "./Footer";
gsap.registerPlugin(ScrollTrigger);

function Meeting({locale}) {
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#contatti",
        start: "top 70%",
        end: "bottom 80%",
        scrub: true,
        fastScrollEnd: true,
      },
    });
    tl.from([".meeting_first_line"], {
      x: -100,
      autoAlpha: 0,
      stagger: 1,
    })
      .from([".meeting_second_line"], {
        x: 100,
        autoAlpha: 0,
        stagger: 1,
      })
      .from([".meeting_third_line"], {
        x: -100,
        autoAlpha: 0,
        stagger: 1,
      })
      .from([".meeting_fourth_line"], {
        x: 100,
        autoAlpha: 0,
        stagger: 1,
      })
      .from([".meeting_fifth_line"], {
        x: -100,
        autoAlpha: 0,
        stagger: 1,
      });
  }, []);

  return (
    <>
      <div className="bg_light_white custom_content_padding overflow-hidden">
        <div>
          <Footer locale={locale} />
        </div>
      </div>
    </>
  );
}

export default Meeting;
