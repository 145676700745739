const Image = ({ src, alt, height, width, onMouseEnter, onMouseLeave }) => {
  return (
    <div className="d-flex flex-column align-items-center w-100 w-sm-unset px-5 px-sm-0 partners_icons">
      <img
        className="z_2"
        src={src}
        alt={alt}
        height={height}
        width={width}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </div>
  );
};

export default Image;
