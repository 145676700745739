import React, { useEffect, useState } from "react";
import smallbgImg from "../assets/images/png/small-bg-forth.png";
import { gsap } from "gsap";
import { DownArrowNoiIcon, UpArrowNoiIcon } from "./Icons";
import aboutArrow from "../assets/images/png/about-arrow.png";
import aboutArrowTwo from "../assets/images/png/about-arrow-2.png";
import aboutArrowThree from "../assets/images/png/about-arrow-3.png";
import businessLeftTextIcon from "../assets/images/svg/small-arrow-icon.svg";

const About = ({locale: {About}}) => {
  const [aboutArrowSrc, setAboutArrowSrc] = useState(aboutArrow)

  useEffect(() => {
    gsap.set(["#about-image-block-2", "#about-image-block-3"], {
      autoAlpha: 0,
    });
    let mm = gsap.matchMedia();
    mm.add("(min-width: 1600px)", () => {
      const tl1 = gsap.timeline({
        scrollTrigger: {
          trigger: "#about",
          start: "top bottom",
          end: "top top",
          scrub: true,
          fastScrollEnd: true,
        },
      });
      tl1
        .to("#hero-large-arrow", {
          scale: 1.2,
          top: "-95%",
          duration: 5,
        })
        .to(
          "#root",
          {
            backgroundColor: "#7EE49C80",
          },
          "<2"
        );

      const tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: "#about",
          start: "top top",
          end: "+=300%",
          scrub: 1,
          pin: true,
          fastScrollEnd: true,
        },
      });

      tl2
        .from("#about-arrow-img", {
          xPercent: -50,
          autoAlpha: 0,
          scale: 0.6,
          onComplete: () => {
            setAboutArrowSrc(aboutArrow)
          }
        })

        .from("#about-image-block", {
          autoAlpha: 0
          // scale: 0,
          // xPercent: -50,
        })
        .fromTo(
          ".left_text_about",
          {
            bottom: "0%",
          },
          {
            bottom: "20%"
          }
        )
        .fromTo(
          "#about-image-block",
          {
            width: "90%",
          },
          {
            width: "70%"
          },
          "<3"
        )
        .to("#about-image-block-1", {
          autoAlpha: 0,
          onReverseComplete: () => {
            setAboutArrowSrc(aboutArrow)
          },
          onComplete: () => {
            setAboutArrowSrc(aboutArrowTwo)
          }
        })
        .fromTo(
          ".left_text_about",
          {
            bottom: "20%",
          },
          {
            bottom: "40%",
          }
        )
        .to("#about-image-block-2", {
          autoAlpha: 1,
        })
        .fromTo(
          "#about-image-block",
          {
            width: "70%",
          },
          {
            width: "60%"
          },
          "<3"
        )

        .to("#about-image-block-2", {
          autoAlpha: 0,
          onReverseComplete: () => {
            setAboutArrowSrc(aboutArrowTwo)
          },
          onComplete: () => {
            setAboutArrowSrc(aboutArrowThree)
          }
        })
        .fromTo(
          ".left_text_about",
          {
            bottom: "40%",
          },
          {
            bottom: "60%",
          }
        )
        .to("#about-image-block-3", {
          autoAlpha: 1,
        })
        .to(
          "#about-image-block-3",
          {
            autoAlpha: 1
          },
          "<3"
        );
    });
    mm.add("(min-width: 1200px) and (max-width:1599.98px)", () => {
      const tl1 = gsap.timeline({
        scrollTrigger: {
          trigger: "#about",
          start: "top bottom",
          end: "top top",
          scrub: true,
          fastScrollEnd: true,
        },
      });
      tl1
        .to("#hero-large-arrow", {
          scale: 1.2,
          top: "-145%",
          duration: 5,
        })
        .to(
          "#root",
          {
            backgroundColor: "#7EE49C80",
          },
          "<2"
        );
      const tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: "#about",
          start: "top top",
          end: "+=300%",
          scrub: 1,
          pin: true,
          fastScrollEnd: true,
        },
      });

      tl2
        .from("#about-arrow-img", {
          xPercent: -50,
          autoAlpha: 0,
          scale: 0.6,
        })
        .from("#about-image-block", {
          autoAlpha: 0,
        })
        .fromTo(
          ".left_text_about",
          {
            bottom: "0%",
            autoAlpha: 0,
          },
          {
            bottom: "20%",
            autoAlpha: 1,
          }
        )
        .fromTo(
          "#about-image-block",
          {
            width: "90%",
          },
          {
            width: "70%",
          },
          "<3"
        )
        .to("#about-image-block-1", {
          autoAlpha: 0,
        })
        .fromTo(
          ".left_text_about",
          {
            bottom: "20%",
          },
          {
            bottom: "40%",
          }
        )
        .to("#about-image-block-2", {
          autoAlpha: 1,
        })
        .fromTo(
          "#about-image-block",
          {
            width: "70%",
          },
          {
            width: "60%",
          },
          "<3"
        )
        .to("#about-image-block-2", {
          autoAlpha: 0,
        })
        .fromTo(
          ".left_text_about",
          {
            bottom: "40%",
          },
          {
            bottom: "60%",
          }
        )
        .to("#about-image-block-3", {
          autoAlpha: 1,
        })
        .to(
          "#about-image-block-3",
          {
            autoAlpha: 1,
          },
          "<3"
        );
    });
    mm.add("(min-width: 992px) and (max-width:1199.98px)", () => {
      const tl1 = gsap.timeline({
        scrollTrigger: {
          trigger: ".about_parent",
          start: "top bottom",
          end: "top top",
          scrub: true,
          fastScrollEnd: true,
        },
      });
      tl1
        .to("#hero-large-arrow", {
          scale: 1.2,
          top: "-190%",
          duration: 5,
        })
        .to(
          "#root",
          {
            backgroundColor: "#7EE49C80",
          },
          "<2"
        );
      const tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: ".about_parent",
          start: "top top",
          end: "+=100%",
          scrub: 1,
          pin: true,
          fastScrollEnd: true,
        },
      });

      tl2
        .from("#about-arrow-img", {
          xPercent: -50,
          autoAlpha: 0,
          scale: 0.6,
        })

        .from("#about-image-block", {
          autoAlpha: 0,
          scale: 0,
          xPercent: -50,
        })
        .fromTo(
          "#about-image-block",
          {
            width: "70%",
          },
          {
            width: "60%",
          },
          "<3"
        )
        .to("#about-image-block-1", {
          autoAlpha: 0,
        })
        .to("#about-image-block-2", {
          autoAlpha: 1,
        })
        .fromTo(
          "#about-image-block",
          {
            width: "60%",
          },
          {
            width: "50%",
          },
          "<3"
        )
        .to("#about-image-block-2", {
          autoAlpha: 0,
        })
        .to("#about-image-block-3", {
          autoAlpha: 1,
        })
        .to(
          "#about-image-block-3",
          {
            autoAlpha: 1,
          },
          "<3"
        );

        let secondSection4 = gsap.timeline({
          scrollTrigger: {
            trigger: ".about_parent",
            start: "top 80%",
            end: "bottom center",
            scrub: true,
            fastScrollEnd: true,
          },
        });
        secondSection4
          .from(".founder_1", {
            xPercent: "-100",
            duration: 1,
            stagger: 0.3,
          })
          .from(".founder_2", {
            xPercent: "100",
            duration: 1,
            stagger: 0.3,
          })
          .from(".founder_3", {
            xPercent: "-100",
            duration: 1,
            stagger: 0.3,
          });
    });
    mm.add("(max-width:991.98px)", () => {
      let secondSection4 = gsap.timeline({
        scrollTrigger: {
          trigger: ".about_parent",
          start: "top 80%",
          end: "bottom center",
          scrub: true,
          fastScrollEnd: true,
        },
      });
      secondSection4
        .from(".founder_1", {
          xPercent: "-100",
          duration: 1,
          stagger: 0.3,
        })
        .from(".founder_2", {
          xPercent: "100",
          duration: 1,
          stagger: 0.3,
        })
        .from(".founder_3", {
          xPercent: "-100",
          duration: 1,
          stagger: 0.3,
        });
    });
  }, []);

  return (
    <>
      <div className="position-relative" id="about_section">
        <div id="about" className="vh-100 w-100 z-10 d-none d-xl-block">
          <div className="position-absolute end-0 text_left_rotate left_text_about d-none d-xl-flex align-items-center pe-5 me-3 me-xl-2 pt-5">
            <p className="color_white_off font_md mb-0">{About.arrowText}</p>
            <div className="ps-4">
              <img src={businessLeftTextIcon} alt="about-small-arrow" />
            </div>
          </div>
          <div className="container position-relative vh-100">
            <div className="d-flex align-items-center flex-column justify-content-between position-relative h-100 top_csutom_space z-10">
              <p className="font_5xl text-white pt-sm-5 custom_letter_spacing d-flex align-items-center">
                {About.title}
                <a href="#timeline">
                  <button className="custom_tab_button_012 personalizzate cursor-pointer align-middle d-flex align-items-center justify-content-center business_btn hero_heading_btn color_dark_gray bg_light_green my-2 mb-sm-0 mt-sm-0 mx-3">
                    {About.titleButton}
                  </button>
                </a>
              </p>
              <div
                id="about-content-2"
                className="d-flex justify-content-end align-items-center flex-grow-1 w-100 about_text_parent_max_w"
              >
                <div
                  className="h-100 d-flex align-items-center justify-content-end position-relative about_arrow_img_width"
                  id="about-arrow-img"
                >
                  <img
                    src={aboutArrowSrc}
                    className="position-absolute end-0 about_img"
                    alt="about_arrow"
                  />
                </div>
                <div
                  id="about-image-block"
                  className="h-100 d-flex align-items-end flex-column gap-2 justify-content-center position-relative scale_8"
                >
                  <div
                    id="about-image-block-1"
                    className="position-absolute about_text_value ps-4 ps-xl-0"
                  >
                    <div className="ps-3 ps-sm-5 ps-lg-0 w-100">
                      <div className="position-absolute small_icon_arrow">
                        <UpArrowNoiIcon />
                      </div>
                      <div className="position-absolute small_icon_arrow_2">
                        <DownArrowNoiIcon />
                      </div>
                      <div className="d-flex align-items-center ps-xl-5 pt-4 mt-xl-3 px-lg-4">
                        <p className="font_6xl color_dark_gray mb-0">
                          {About.section1.title}
                        </p>
                      </div>
                      <div>
                        <h4 className="font_2xl color_dark_gray ps-4 ps-xl-5">
                          {About.section1.text}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    id="about-image-block-2"
                    className="position-absolute about_text_value ps-4 ps-xl-0"
                  >
                    <div className="ps-3 ps-sm-5 ps-lg-0 w-100">
                      <div className="position-absolute small_icon_arrow">
                        <UpArrowNoiIcon />
                      </div>
                      <div className="position-absolute small_icon_arrow_2">
                        <DownArrowNoiIcon />
                      </div>
                      <div className="d-flex align-items-center ps-xl-5 pt-4 mt-xl-3 px-lg-4">
                        <p className="font_6xl color_dark_gray mb-0">
                          {About.section2.title}
                        </p>
                      </div>
                      <div>
                        <h4 className="font_2xl color_dark_gray ps-4 ps-xl-5 mb-0">
                          {About.section2.text}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    id="about-image-block-3"
                    className="position-absolute about_text_value ps-4 ps-xl-0"
                  >
                    <div className="ps-3 ps-sm-5 ps-lg-0 w-100">
                      <div className="position-absolute small_icon_arrow">
                        <UpArrowNoiIcon />
                      </div>
                      <div className="position-absolute small_icon_arrow_2">
                        <DownArrowNoiIcon />
                      </div>
                      <div className="d-flex align-items-center ps-xl-5 pt-4 mt-xl-3 px-lg-4">
                        <p className="font_6xl color_dark_gray mb-0">
                          {About.section3.title}
                        </p>
                      </div>
                      <div>
                        <h4 className="font_2xl color_dark_gray ps-4 ps-xl-5 mb-0">
                          {About.section3.text}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="vh-100 overflow-hidden about_parent z_5 top_minus_space d-xl-none"
        id="about_section"
      >
        <div className="position-relative h-100 z_1">
          <div className="position-absolute d-none d-sm-block d-lg-block z_negative r-tablet-0 l-tablet-0">
            <img
              className="w-100 small_tab_arrow_img_forth"
              src={smallbgImg}
              alt="arrow-tab-img"
            />
          </div>
          <div className="container h-100 pb-5 pb-lg-0 tablet-max-width">
            <div className="text-center pt-5 about_text_size_two">
              <p className="font_5xl text-white pt-sm-5 custom_letter_spacing d-flex align-items-center justify-content-center">
                {About.titleWithoutLink}
              </p>
            </div>
            <div className="row align-items-center mb-sm-5 top-tablet">
              <div className="col-12 custom_top_spacing mb-4 mb-sm-0 founder_1">
                <div className="position-relative roadmap_para">
                  <div className="position-absolute small_icon_arrow">
                    <UpArrowNoiIcon />
                  </div>
                  <div className="position-relative">
                    <div className="d-flex align-items-sm-center ps-xl-5 pt-2 pt-sm-3 mt-xl-3">
                      <p className="font_8xl fw-bold color_dark_gray ps-sm-3 mb-0">
                        {About.section1.title}
                      </p>
                    </div>
                    <h4 className="font_8xl color_dark_gray mt-4 mt-xl-5 pt-xl-5 px-lg-4 ps-xl-5 pe-xxl-5 me-xxl-5">
                      {About.section1.text}
                    </h4>
                    <div className="position-absolute small_icon_arrow_2">
                      <DownArrowNoiIcon />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 py-sm-5 my-sm-4 mt-lg-5 founder_2">
                <div className="position-relative roadmap_para">
                  <div className="position-absolute small_icon_arrow">
                    <UpArrowNoiIcon />
                  </div>
                  <div className="position-relative">
                    <div className="d-flex align-items-sm-center ps-xl-5 pt-2 pt-sm-3 mt-xl-3">
                      <p className="font_8xl fw-bold color_dark_gray ps-sm-3 mb-0">
                        {About.section2.title}
                      </p>
                    </div>
                    <h4 className="font_8xl color_dark_gray mt-4 mt-xl-5 pt-xl-5 px-lg-4 ps-xl-5 pe-xxl-5 me-xxl-5">
                      {About.section2.text}
                    </h4>
                    <div className="position-absolute small_icon_arrow_2">
                      <DownArrowNoiIcon />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-4 mt-lg-5 mt-sm-0 founder_3">
                <div className="position-relative roadmap_para">
                  <div className="position-absolute small_icon_arrow">
                    <UpArrowNoiIcon />
                  </div>
                  <div className="position-relative">
                    <div className="d-flex align-items-sm-center ps-xl-5 pt-2 pt-sm-3 mt-xl-3">
                      <p className="font_8xl fw-bold color_dark_gray ps-sm-3 mb-0">
                        {About.section3.title}
                      </p>
                    </div>
                    <h4 className="font_8xl color_dark_gray mt-4 mt-xl-5 pt-xl-5 px-lg-4 ps-xl-5 pe-xxl-5 me-xxl-4">
                      {About.section3.text}
                      <div className="position-absolute small_icon_arrow_2">
                        <DownArrowNoiIcon />
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
